import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { AuthService } from '../../account/auth.service';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HomeService } from 'app/shared/general/services/home.service';
import { AccountsService } from '../general/services/accounts.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';

@Component({
  moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  listTitles: any[];
  location: Location;
  nativeElement: Node;
  toggleButton;
  sidebarVisible: boolean;
  botID: number = parseInt(localStorage.getItem('botID'), 10);
  botUniqueID: string = localStorage.getItem('botUnique');
  customerEmail: string = localStorage.getItem('Customer_Email');
  customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
  customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
  botList;
  warning: boolean = false;
  noMail: boolean = false;
  success: boolean = false;
  email;
  confirmbool: boolean = false;
  botListStatus: boolean = false;
  botContent: boolean = false;
  botUrl = environment.botUrl;
  showOrgLoader: boolean = false;
  setBot: boolean = false;
  organizationsList = [];
  token = localStorage.getItem('ACCESS_TOKEN') || '';
  cid = localStorage.getItem('Customer_ID') || '';
  userLetter = localStorage.getItem('Customer_Name') ? localStorage.getItem('Customer_Name')[0] : 'A';
  supportUrl = environment.supportUrl;

  @Input() isMinimized: boolean = false;
  @ViewChild('confirmEmail') confirmEmail: ModalDirective;
  isCollapsed = true;
  @ViewChild("navbar-cmp") button;

  menuItems = [];
  passToken: any;

  constructor(location: Location,
    private element: ElementRef,
    private router: Router,
    private authService: AuthService,
    private homeService: HomeService, private accountService: AccountsService) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {

    this.botID = parseInt(localStorage.getItem('botID'), 10);
    this.botUniqueID = localStorage.getItem('botUnique');
    if (this.botID) this.setBot = true;

    this.listTitles = ROUTES.filter(listTitle => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });

    this.getTokenWithMail(this.customerEmail);


    this.router.events.subscribe((event) => {
      this.botID = parseInt(localStorage.getItem('botID'), 10);
    });

    if (this.customerType == 0) {
      this.getMasterBotlist();
    } else if (this.customerType == 1) {
      this.getVendorBotlist();
    } else {
      this.getBotList();
    }

  }

  getTokenWithMail(mail) {
    this.accountService.getTokenFromMail(mail).subscribe((res) => {
      if (res?.token) this.passToken = res.token;
      this.setMenuRoutes();
    });
  }

  setMenuRoutes() {
    this.menuItems = [

      {
        menu: 'Home',
        routeTo: '/home',
        icon: 'assets/images/icons/menu/home.svg',
      },
      {
        menu: 'Canvas',
        routeTo: '/canvas/design',
        icon: 'assets/images/icons/menu/canvas.svg',
      },
      {
        menu: 'Support',
        openUrl: `${this.supportUrl}/redirect-login?_=${this.passToken}`,
        icon: 'assets/images/icons/menu/support.svg',
      },
      {
        menu: 'Campaign',
        routeTo: '/campaign',
        icon: 'assets/images/icons/menu/campaign.svg',
      },
      {
        menu: 'Analytics',
        routeTo: '/analytics',
        icon: 'assets/images/icons/menu/analytics.svg',
      },
      {
        menu: 'Channels',
        routeTo: '/channels',
        icon: 'assets/images/icons/menu/channels.svg',
      },
      {
        menu: 'Integrations',
        routeTo: '/integrations',
        icon: 'assets/images/icons/menu/integration.svg',
      },
      {
        menu: 'Teams',
        routeTo: '/teams',
        icon: 'assets/images/icons/menu/team.svg',
      },
      // {
      //   menu: 'CX Hub',
      //   routeTo: '/cxhub',
      //   icon: 'assets/images/icons/menu/cxhub.svg',
      // },

    ];
  }
  getBotList() {
    this.homeService.getBotList({ "customer_id": this.customerID, "customer_type": this.customerType })
      .subscribe((data) => {
        this.email = data.profileData.emailid;
        this.botList = data.botDetails.botDetails;
        this.homeService.updateBotsList(this.botList);
        if (isNaN(this.botID) && this.botList.length > 0) {
          localStorage.setItem('botID', this.botList[0].botid);
          localStorage.setItem('channelID', this.botList[0].channelid);
          localStorage.setItem('botUnique', this.botList[0].bot_unique);
          localStorage.setItem('botName', this.botList[0].bot_name);
          this.botID = this.botList[0].botid;
          this.botUniqueID = this.botList[0].bot_unique;
        }
        this.botListStatus = true;
        if (data.botDetails.botDetails.length > 0) {
          this.botContent = true;
        }
        if (data.profileData.isconfirmed == false) {
          this.warning = true;
        }
      },
        (error) => {
          console.log("Bot list error");
        });
  }

  getMasterBotlist() {
    this.homeService.getMasterBotList()
      .subscribe((data) => {
        this.botListStatus = true;
        this.botContent = true;
        this.botList = [];
        let botsList = data;

        for (let i in botsList) {
          this.botList.push({
            vendor_name: botsList[i].vendor_name,
            bots: []
          });
          for (let j in botsList[i].organisations) {
            for (let k in botsList[i].organisations[j].bots) {
              this.botList[i].bots.push({
                organization: botsList[i].organisations[j].organisation_name,
                bot: botsList[i].organisations[j].bots[k].bot_name,
                bot_id: botsList[i].organisations[j].bots[k].bot_id,
                bot_unique: botsList[i].organisations[j].bots[k].bot_unique,
              });
              if (!this.setBot) {
                localStorage.setItem('botID', botsList[i].organisations[j].bots[k].bot_id);
                localStorage.setItem('channelID', botsList[i].organisations[j].bots[k].channelid);
                localStorage.setItem('botUnique', botsList[i].organisations[j].bots[k].bot_unique);
                localStorage.setItem('botName', botsList[i].organisations[j].bots[k].bot_name);
                this.botID = botsList[i].organisations[j].bots[k].bot_id;
                this.botUniqueID = botsList[i].organisations[j].bots[k].bot_unique;
                this.setBot = true;
              }
            }

          }

        }
      },
        error => {
          console.log(error);
        });
  }

  getVendorBotlist() {
    this.homeService.getVendorBotList(this.customerID)
      .subscribe((data) => {
        this.botListStatus = true;
        this.botContent = true;
        this.botList = data[0].organisations;
        this.botList.forEach(ae => {
          ae.bots.forEach(ao => {
            if (!this.setBot) {
              localStorage.setItem('botID', ao.bot_id);
              localStorage.setItem('ChannelID', ao.channelid);
              localStorage.setItem('botUnique', ao.bot_unique);
              localStorage.setItem('botName', ao.bot_name);
              this.botID = ao.bot_id;
              this.botUniqueID = ao.bot_unique;
              this.setBot = true;
            }
          })
        });
      },
        error => {
          console.log(error);
        });
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    const parts = titlee.split('/');
    if (parts.length > 1) return parts[1];
    else return '';
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  logoutFn() {
    this.authService.logout();
  }


  selectBot = (botidname) => {
    let bot = botidname.split(",");
    let botid = bot[0];
    let botname = bot[1];
    let botuniq = bot[2];
    let channelid = bot[3];
    localStorage.setItem('botID', botid);
    localStorage.setItem('botName', botname);
    localStorage.setItem('botUnique', botuniq);
    localStorage.setItem('channelID', channelid);
    this.botID = botid;
    this.botUniqueID = botuniq;
    let currentURL = this.router.url.substr(1);
    this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
      if (currentURL.split('/').length == 2 && currentURL.split('/')[0] == 'automation') this.router.navigate(['automation']);
      else this.router.navigate([currentURL]);
    });
  }

  openBot() {
    window.open(this.botUrl + localStorage.getItem('botUnique'), '_blank').focus();
  }

  resendmail = () => {
    this.confirmbool = true;
    let data = {};
    data['email'] = this.email;
    this.homeService.resendEmailConfirmation(data).subscribe((data) => {
      if (data.text() == 'True') {
        this.success = true;
        this.confirmbool = false;
      }
    },
      (error) => {
        console.log(error);
      })
  }


  // sidebarMinimize() {
  //   this.isMinimized = !this.isMinimized;
  //   const sidebarbar = document.getElementsByClassName('sidebar')[0];
  //   const panel = document.getElementsByClassName('main-panel')[0];
  //   if (!this.isMinimized) {
  //     sidebarbar.classList.remove('minibar');
  //     panel.classList.remove('minipanel');
  //   } else {
  //     sidebarbar.classList.add('minibar');
  //     panel.classList.add('minipanel');
  //   }
  // }

  // isMin() {
  //       return this.isMinimized;
  //   }


}