import { Routes } from '@angular/router';

import { AccountComponent } from './account/account.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { NotFoundComponent } from './shared/general/general-pages/not-found/not-found.component';

import { AuthGuard } from './account/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      }],
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: AccountComponent,
    pathMatch: 'full',
    data: { page: 'login' }
  },
  {
    path: 'register',
    component: AccountComponent,
    pathMatch: 'full',
    data: { page: 'register' }
  },
  {
    path: 'forget_password',
    component: AccountComponent,
    pathMatch: 'full',
    data: { page: 'forget_password' }
  },
  {
    path: 'confirmemail',
    component: AccountComponent,
    pathMatch: 'full',
    data: { page: 'confirmemail' }
  }, {
    path: 'passwordrest',
    component: AccountComponent,
    pathMatch: 'full',
    data: { page: 'passwordrest' }
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  },
]