<div class="html-404">
    <div class="body-404">
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="main-404">
            <h1>404</h1>
            <p>Page not found!</p>
            <button type="button" [routerLink]="['/home']">Go Back to Home</button>
        </div>
    </div>
</div>