<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <div class="nav-item btn-rotate" ngbDropdown placement="bottom-right" #menuDrop="ngbDropdown">
        <button type="button" title="Click to minimise tabs" class="sidebar-toggler" ngbDropdownToggle
          id="navbarMenuLink">
          <img src="assets/images/icons/menu.svg" alt="" width="35">
        </button>

        <div ngbDropdownMenu aria-labelledby="navbarMenuLink" class="dropdown-menu">
          <div class="menu-row row">
            <div class="col-md-4 text-center" *ngFor="let item of menuItems">
              <a *ngIf="item.routeTo" class="nav-link" [title]="item.menu" (click)="menuDrop.close()"
                [routerLink]="[item.routeTo]">
                <img [src]="item.icon" width="35" class="mt-1">
                <span>{{item.menu}}</span>
              </a>
              <a *ngIf="item.openUrl" class="nav-link" [title]="item.menu" (click)="menuDrop.close()" target="_blank"
                [href]="item.openUrl">
                <img [src]="item.icon" width="35" class="mt-1">
                <span>{{item.menu}}</span>
              </a>
            </div>
          </div>
        </div>

      </div>
      <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
    </div>
    <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="collapseExample" *ngIf="isCollapsed">
      <!-- [ngbCollapse] -->
      <form>
        <div class="input-group no-border sel-bot" *ngIf="!botListStatus">
          <select class="form-control changebot" disabled>
            <option>Loading botlist...</option>
          </select>
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="input-group no-border sel-bot" *ngIf="botListStatus && botContent"
          title="Click to select active bot">
          <select *ngIf="customerType!=0 && customerType!=1" class="form-control change-bot"
            (change)="selectBot($event.target.value)">
            <option *ngFor="let bot of botList; let i = index"
              value="{{bot.botid}},{{bot.bot_name}},{{bot.bot_unique}},{{bot.channelid}}"
              [selected]="bot.botid == botID">{{bot.bot_name}}</option>
          </select>

          <select *ngIf="customerType==0" class="form-control change-bot" (change)="selectBot($event.target.value)">
            <optgroup *ngFor="let vendor of botList"
              [label]="vendor.bots.length>0 ? vendor.vendor_name : vendor.vendor_name + ' (No Bots) '">
              <option *ngFor="let bot of vendor.bots"
                value="{{bot.bot_id}},{{bot.bot_name}},{{bot.bot_unique}},{{bot.channelid}}"
                [selected]="bot.bot_id == botID">
                <!-- <span *ngFor="let bot of org.bots">{{bot.bot_name}}</span> -->{{bot.bot}} ({{bot.organization}})
              </option>
            </optgroup>
          </select>

          <select *ngIf="customerType==1" class="form-control change-bot" (change)="selectBot($event.target.value)">
            <optgroup *ngFor="let org of botList; let i=index;" [label]="org.organisation_name">
              <option *ngFor="let bot of org.bots"
                value="{{bot.bot_id}},{{bot.bot_name}},{{bot.bot_unique}},{{bot.channelid}}"
                [selected]="bot.bot_id == botID">{{bot.bot_name}}</option>
            </optgroup>
          </select>
        </div>
      </form>
      <ul class="navbar-nav ml15">
        <li class="nav-item btn-rotate c-p" *ngIf="botList?.length>0">
          <a class="nav-link d-flex" (click)="openBot()" title="Run Bot">
            <i class="fa fa-play-circle green-grad-i"></i>
            <div class="d-grid">
              <span class="my-auto ml-1">Run Bot</span>
              <small class="pub-version">(Published Version)</small>
            </div>
          </a>
        </li>
        <li class="nav-item btn-rotate c-p" ngbDropdown placement="bottom-left" *ngIf="warning">
          <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink" title="Click to view notifications">
            <span [ngClass]="{'red':warning,'green':!warning}"><i class="fa fa-bell fs23" aria-hidden="true"></i></span>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem (click)="confirmEmail.show()">
              <i class="fa fa-exclamation-triangle red" aria-hidden="true"></i>
              Confirm Email
            </a>
          </div>
        </li>
        <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
          <a class="nav-link user-name" ngbDropdownToggle id="navbarDropdownMenuLink" title="Account">
            {{userLetter}}
            <!-- <img src="assets/images/icons/menu.svg" width="28" class="mt-1"> -->
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
            <div class="toggle-row row">
              <div class="col-md-6 text-center">
                <a class="nav-link" [routerLink]="['/profile']" title="Your profile">
                  <i class="fa fa-user"></i>
                  <span>Profile</span>
                </a>
              </div>
              <div class="col-md-6 text-center">
                <a class="nav-link" href="https://support.creyoface.com/" target="_blank" title="Manage Live Support">
                  <img src="assets/images/icons/customer-service.svg" width="28" class="mt-1">
                  <span>Support</span>
                </a>
              </div>
              <div class="col-md-6 text-center">
                <a class="nav-link" (click)="logoutFn()" title="Logout">
                  <i class="fa fa-sign-out" aria-hidden="true"></i>
                  <span>Logout</span>
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div bsModal #confirmEmail="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm your mail</h4>
      </div>
      <div class="modal-body" *ngIf="!success">
        <p *ngIf="!noMail">We have sent a mail to your registered mail. Please check and confirm.</p>
        <div class="form-group" *ngIf="noMail">
          <input class="w-100 form-control" type="email" name="email" placeholder="Email" [(ngModel)]="email">
        </div>
      </div>
      <div class="modal-body" *ngIf="success">
        <p>Successfully confirmed.</p>
      </div>
      <div class="modal-footer">
        <a (click)="noMail=!noMail" *ngIf="!noMail" class="href">Didn't get a mail?</a>
        <button (click)="resendmail()" *ngIf="noMail && !success" type="button" class="btn btn-gradient"
          data-dismiss="modal"><i *ngIf="confirmbool" class="fa fa-circle-o-notch fa-spin"></i> Send confirmation
          mail</button>
        <button type="button" class="btn btn-default" (click)="confirmEmail.hide()">Close</button>
      </div>
    </div>
  </div>
</div>