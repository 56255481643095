import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-admin-layout',
	templateUrl: './admin-layout.component.html',
	styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

	constructor(
		private _renderer2: Renderer2,
		private router: Router,
		@Inject(DOCUMENT) private _document: Document
	) { }

	botName = localStorage.getItem('botName');
	customerName = localStorage.getItem('Customer_Name');
	customerEmail = localStorage.getItem('Customer_Email');
	showSideBar = false;
	showSideFor = ['canvas', 'campaign', 'channels', 'teams'];

	ngOnInit() {
		let bot_script = this._renderer2.createElement('script');
		bot_script.text = `
		!function(e,t,a){var c=e.head||e.getElementsByTagName("head")[0],n=e.createElement("script");n.async=!0,n.defer=!0, n.type="text/javascript",n.src=t+"/creyoface_widget.js?config="+JSON.stringify(a),c.appendChild(n)}(document,"`+ environment.botStaticUrl + `",{bot_key:"MTIy",pos:"left",icon:"https://creyoface-data.s3.ap-south-1.amazonaws.com/Production/29/data/botdata/1574156464324.gif",open:"false"});
		`;

		this._renderer2.appendChild(this._document.body, bot_script);

		let script = this._renderer2.createElement('script');
		script.text = `
		function onLoadCreyoface() {
            var iFrame = document.getElementById('chat_frame');
            let data = {
                'cf_name': '`+ this.customerName + `',
                'cf_email': '`+ this.customerEmail + `',
                'cf_bot': '`+ this.botName + `'
            }
            iFrame.contentWindow.postMessage(data, "*");
        }
		`;

		const currentUrl = this.router.url;
		this.checkSplittedUrl(currentUrl);

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.checkSplittedUrl(event.url)
			}
		});

		this._renderer2.appendChild(this._document.body, script);
	}

	checkSplittedUrl(url) {
		const parts = url.split('/');
		const filteredParts = parts.filter(Boolean);
		this.showSideBar = filteredParts.some(element => this.showSideFor.includes(element));
	}
}
