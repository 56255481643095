import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Location} from '@angular/common';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  location: Location;
  pagePermissions:any;
  
  constructor(private authService: AuthService, private router: Router, location:Location, private toastr: ToastrService){
    this.location = location;
    this.router.events.subscribe((event: Event) => {  
      
      if (event instanceof NavigationEnd) {
        this.pagePermissions = JSON.parse(localStorage.getItem('Page_Permissions'));
        if(!this.pagePermissions?.analytics_view){
          if(event.url=='/analytics'){
            this.toastr.warning("You have no permission to view Analytics page.");
            this.router.navigate(['home']);
          }
        }
        if(!this.pagePermissions?.show_settings_page){
          if(event.url=='/settings'){
            this.toastr.warning("You have no permission to view Settings page.");
            this.router.navigate(['home']);
          }
        }
        if(!this.pagePermissions?.show_broadcast_page){
          if(event.url=='/broadcast'){
            this.toastr.warning("You have no permission to view Broadcast page.");
            this.router.navigate(['home']);
          }
        }
      }

    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  	if(!this.authService.isLoggedIn()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;

  }
}