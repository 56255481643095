<div class="wrapper">
    <div *ngIf="showSideBar" class="sidebar" data-color="white" data-active-color="danger">
        <sidebar-cmp></sidebar-cmp>
    </div>
    <div class="main-panel" [class]="showSideBar ? 'side-on' : 'side-off'">
        <navbar-cmp></navbar-cmp>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
        <footer-cmp></footer-cmp>
    </div>
</div>