<div class="content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-6 h-100vh d-flex b-r">
				<div class="m-auto">
					<h1 class="info-h1" [innerHTML]="showInfo"></h1>
					<img class="bg-bubbles" src="../../assets/images/bubbles.png">
				</div>
			</div>

			<div class="col-md-6 h-100vh contents d-grid">
				<div class="row justify-content-center my-auto">
					<div class="col-md-8 boxed">
						<div class="m-4">
							<h3 class="text-center">{{title}}</h3>
						</div>

						<div class="form" *ngIf="login">
							<form [formGroup]="loginForm" (ngSubmit)="loginFn()" class="mb-4">
								<div class="form-group first">
									<label for="username">Email</label>
									<input type="text" formControlName="email"
										[ngClass]="{ 'has-error': isloginSubmitted && formControls.email?.errors }"
										autocomplete="email" class="form-control" id="username"
										placeholder="Enter Email">
									<span class="text-danger"
										*ngIf="isloginSubmitted && formControls.email.errors?.required">Email is
										required</span>
									<span class="text-danger"
										*ngIf="isloginSubmitted && formControls.email.errors?.email">Enter a valid
										email</span>
								</div>
								<div class="form-group last mb-4">
									<label for="password">Password</label>
									<div class="d-flex">
										<input type="password" formControlName="password" placeholder="Enter Password"
											[ngClass]="{ 'has-error': isloginSubmitted && formControls.password.errors }"
											autocomplete="password" [type]="showPassword ? 'text' : 'password'"
											class="form-control" id="password">
										<i class="fa eyes"
											[ngClass]="{'fa-eye' : showPassword, 'fa-eye-slash': !showPassword}"
											(click)="showPassword=!showPassword"></i>
									</div>
									<span class="text-danger"
										*ngIf="isloginSubmitted && formControls.password.dirty && formControls.password.errors ">Password
										is required</span>
								</div>

								<div class="d-flex mb-4 align-items-center px-3">
									<span class="mr-auto">
										<a *ngIf="login" [routerLink]="['/register']" class="forgot-pass">New User?</a>
									</span>
									<span class="ml-auto">
										<a *ngIf="!confirmMail" [routerLink]="['/forget_password']"
											class="forgot-pass">Forgot Password?</a>
									</span>
								</div>
								<div class="px-3 w-100 text-center">
									<button type="submit" class="btn"><i *ngIf="islogging"
											class="fa fa-spin fa-spinner grad-creyo fa-pulse"></i> Login</button>
								</div>
								<hr class="or-border">
								<div class="w-100 text-center">
									<button type="button" class="login-with-google-btn">
										Sign in with Google
									</button>
								</div>
							</form>
						</div>

						<div class="form" *ngIf="register">
							<form [formGroup]="registerForm" (ngSubmit)="registerFn()" class="mb-4">
								<div class="form-group">
									<label for="email">Email</label>
									<input type="email" name="emailid" placeholder="Email" formControlName="emailid"
										class="form-control" autocomplete="off"
										[ngClass]="{ 'has-error': isregisterSubmitted && formControlsRF.emailid?.errors || !available}"
										(keyup)="checkEmailAvailability(registerForm.value.emailid)"
										(change)="checkEmailAvailability(registerForm.value.emailid)" />
									<small class="text-danger"
										*ngIf="isregisterSubmitted && formControlsRF.emailid.errors?.required && !checkingMail">Email
										is required</small>
									<small class="text-danger" *ngIf="!isValidEmail && !checkingMail">Enter
										a valid email</small>
									<small class="text-danger" *ngIf="!available && !checkingMail">Email already
										registered</small>
									<small class="text-info" *ngIf="checkingMail">Checking Email availability...</small>
								</div>
								<div class="form-group">
									<label for="password" class="mb-1"
										[ngClass]="{'pwd-mt15': registerForm.value.password}">Password</label>
									<input [type]="showPasswordReg ? 'text' : 'password'" name="password"
										class="form-control" placeholder="Password" formControlName="password"
										autocomplete="password"
										[ngClass]="{ 'has-error': isregisterSubmitted && formControlsRF.password?.errors }" />
									<i class="fa eyes"
										[ngClass]="{'fa-eye' : showPasswordReg, 'fa-eye-slash': !showPasswordReg}"
										(click)="showPasswordReg=!showPasswordReg"></i>
									<small class="text-danger"
										*ngIf="isregisterSubmitted && formControlsRF.password?.errors?.required">Password
										is
										required</small>
									<div class="row text-danger" *ngIf="registerForm.value.password">
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('.{8,}$', registerForm.value.password)}">At
												least 8 characters</small>
										</div>
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('.*[A-Z].*', registerForm.value.password)}">
												1 Uppercase Character</small>
										</div>
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('.*[a-z].*', registerForm.value.password)}">
												1 Lowercase Character</small>
										</div>
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('special_character', registerForm.value.password)}">
												1 Special Character</small>
										</div>
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('.*[0-9].*$', registerForm.value.password)}">
												1 Number</small>
										</div>
									</div>
								</div>

								<div class="d-flex mb-4 align-items-center px-3">
									<span class="mr-auto">
										<a *ngIf="register" [routerLink]="['/login']" class="forgot-pass">Already
											registered?</a>
									</span>
									<span class="ml-auto">
										<a *ngIf="!confirmMail" [routerLink]="['/forget_password']"
											class="forgot-pass">Forgot Password?</a>
									</span>
								</div>
								<div class="px-3 w-100 text-center">
									<button [disabled]="registering" type="submit" class="btn btn-gradient">
										<i *ngIf="registering" class="fa fa-spin fa-spinner grad-creyo fa-pulse"></i>
										Register
									</button>
								</div>
								<hr class="or-border">
								<div class="w-100 text-center">
									<button type="button" class="login-with-google-btn">
										Sign up with Google
									</button>
								</div>
							</form>
						</div>

						<div class="form" *ngIf="forget">
							<form [formGroup]="forgetForm" (ngSubmit)="forgetFn()" class="mb-4">
								<div class="form-group">
									<label for="password">Email</label>
									<input type="text" name="emailid" placeholder="Enter Registered Email"
										formControlName="emailid" class="form-control" autocomplete="email"
										[ngClass]="{ 'has-error': isforgetSubmitted && formControlsFF.emailid?.errors }" />
									<small class="text-danger"
										*ngIf="isforgetSubmitted && formControlsFF.emailid.errors?.required">Email is
										required</small>
									<small class="text-danger"
										*ngIf="isforgetSubmitted && formControlsFF.emailid.errors?.email">Enter a valid
										email</small>
									<small class="text-danger"
										*ngIf="available && !checkingMail && isCheckedEmail">Email
										not registered</small>
									<small class="text-info" *ngIf="checkingMail">Checking if email is
										registered...</small>
								</div>

								<div class="d-flex mb-4 align-items-center px-3">
									<span class="mr-auto">
										<a *ngIf="forget" [routerLink]="['/login']" class="forgot-pass">Login</a>
									</span>
									<span class="ml-auto">
										<a [routerLink]="['/register']" class="forgot-pass">Register</a>
									</span>
								</div>
								<div class="px-3">
									<button type="submit" class="btn btn-block btn-gradient"
										[disabled]="formControlsFF.emailid.errors?.required">
										<i class="fa fa-circle-o-notch fa-spin" *ngIf="isForgetsending"></i>
										Send Verification Email
									</button>
								</div>
								<div class="text-success text-center d-block mt-3" *ngIf="sendmail">Mail sent to your
									registered mail. Please confirm to continue.</div>
							</form>
						</div>

						<div class="form" *ngIf="confirmMail">
							<div *ngIf="showLoader" class="text-center">
								<i class="fa fa-circle-o-notch fa-spin" style="font-size:24px"></i><br>
								<p>Validating.. Please wait.</p>
							</div>
							<div *ngIf="!showLoader && !error">
								<h1>{{title}}</h1>
								<div class="form-group">
									<i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>&nbsp;Please wait
								</div>
								<input type="submit" name="signup_submit" value="Submit" />
							</div>
							<div *ngIf="error">
								<div class="invalid">
									<p>Error in confirmation..</p>
									<p>Please register to continue.</p>
								</div>
							</div>
						</div>

						<div class="form" *ngIf="resetpassword">
							<form [formGroup]="resetPwdForm" (ngSubmit)="resetPwdFn()" class="mb-4">
								<div class="form-group">
									<label for="password">Password</label>
									<input type="password" class="form-control" name="password" placeholder="Password"
										formControlName="password" autocomplete="password"
										[ngClass]="{ 'has-error': formControlsFP.password.dirty && formControlsFP.password?.errors }" />
									<small class="text-danger"
										*ngIf="isResetSubmitted && formControlsFP.password.errors">Password is
										required</small>
									<div class="row text-danger" *ngIf="resetPwdForm.value.password">
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('.{8,}$', resetPwdForm.value.password)}">At
												least 8 characters</small>
										</div>
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('.*[A-Z].*', resetPwdForm.value.password)}">
												1 Uppercase Character</small>
										</div>
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('.*[a-z].*', resetPwdForm.value.password)}">
												1 Lowercase Character</small>
										</div>
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('special_character', resetPwdForm.value.password)}">
												1 Special Character</small>
										</div>
										<div class="col-md-6">
											<small
												[ngClass]="{'text-success':checkRegex('.*[0-9].*$', resetPwdForm.value.password)}">
												1 Number</small>
										</div>
									</div>
								</div>
								<div class="form-group">
									<label for="password">Re-enter Password</label>
									<input type="password" class="form-control" name="password" #resetPasswordMatch
										placeholder="Re-enter Password"
										[ngClass]="{ 'has-error':formControlsFP.password.touched && !pwdresmatch }"
										(blur)="confirmResetPwd(resetPasswordMatch.value)" />
									<span class="text-danger" *ngIf="isResetSubmitted && !pwdresmatch">Password
										Mismatch!</span>
								</div>
								<div class="px-3">
									<button type="submit" class="btn btn-block btn-gradient"> Submit </button>
								</div>
								<div class="registered" *ngIf="sendmail">Mail sent to your registered mail. Please
									confirm to continue.</div>
							</form>
						</div>

					</div>
				</div>

			</div>

		</div>
	</div>
</div>