import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './auth.service';
import { Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
declare function removeBot(): any;

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

	login: boolean;
	register: boolean;
	title: string;
	url;
	loginForm: FormGroup;
	registerForm: FormGroup;
	forgetForm: FormGroup;
	resetPwdForm: FormGroup;
	isloginSubmitted: boolean = false;
	isregisterSubmitted: boolean = false;
	isforgetSubmitted: boolean = false;
	islogging: boolean = false;
	approved: boolean = true;
	approvedData;
	pwdresmatch: boolean = false;
	forget: boolean = false;
	confirmMail: boolean = false;
	showLoader: boolean = true;
	error: boolean = false;
	registered: boolean = false;
	confirmed: boolean = false;
	registering: boolean = false;
	sendmail: boolean = false;
	new_pwd: boolean = false;
	resetpassword: boolean = false;
	pwdconfirmed: boolean = false;
	showPassword: boolean = false;
	showPasswordReg: boolean = false;
	resetMail = '';
	pagePermissions;
	country: any;
	@ViewChild('password1') input;
	@ViewChild('resetPasswordMatch') input1;
	dialCode: number;
	introText = ['<span>One chatbot</span> for all your business conversations', 'Automate Your Customer Interactions with Our <span>No-Code Chatbot Builder</span> Platform'];
	showInfo = '';


	constructor(private router: Router,
		private route: ActivatedRoute,
		private authService: AuthService,
		private formBuilder: FormBuilder,
		private _http: Http, private toastr: ToastrService) { }

	ngOnInit() {
		removeBot();

		this.showInfo = this.getRandomInfo();

		this._http.get(`../../../assets/js/countrycodes.json`).subscribe((res: any) => {
			this.country = res.json();
		});

		this.route.data.subscribe(v => { this.url = v.page });
		if (this.url == 'login') {
			this.login = true;
			this.register = false;
			this.confirmMail = false;
			this.forget = false;
			this.resetpassword = false;
			this.title = 'Welcome Back!';
		}
		else if ((this.url == 'register')) {
			this.login = false;
			this.register = true;
			this.forget = false;
			this.confirmMail = false;
			this.resetpassword = false;
			this.title = 'Register';
		}
		else if ((this.url == 'forget_password')) {
			this.login = false;
			this.register = false;
			this.forget = true;
			this.confirmMail = false;
			this.resetpassword = false;
			this.title = 'Forget Password';
		}
		else if ((this.url == 'confirmemail')) {
			this.showLoader = true;
			this.login = false;
			this.register = false;
			this.forget = false;
			this.confirmMail = true;
			this.resetpassword = false;
			this.title = 'Confirm Mail';
			this.route.queryParams.subscribe(params => {
				let token = params['token'];
				if (token) {
					this.authService.confirmMail(token).subscribe(
						(data: any) => {
							if (data?.is_email_confirmed) {
								this.router.navigateByUrl('/login');
								this.confirmed = true;
								this.login = true;
								this.register = false;
								this.confirmMail = false;
								this.forget = false;
								this.resetpassword = false;
								this.title = 'Login';
							}
							else {
								this.toastr.error("Email Confirmation Failed! Try Again.")
								this.confirmed = true;
								this.login = false;
								this.register = false;
								this.confirmMail = false;
								this.forget = true;
								this.resetpassword = false;
								this.title = 'Forget Password';
							}
						},
						error => {
							console.log(error);
						})
				} else {
					this.error = true;
				};
				this.showLoader = false;
			});
		}
		else if ((this.url == 'passwordrest')) {
			this.showLoader = true;
			this.login = false;
			this.register = false;
			this.forget = false;
			this.confirmMail = true;
			this.resetpassword = false;
			this.title = 'Reset Password';
			this.route.queryParams.subscribe(params => {
				let token = params['token'];
				if (token) {
					this.authService.confirmMailforPassword(token).subscribe(
						(data: any) => {
							if (data.text() == 'False') {
								this.showLoader = false;
								this.login = false;
								this.register = false;
								this.forget = true;
								this.confirmMail = false;
								this.resetpassword = false;
								this.title = 'Forget Password';
								alert('Invalid Token.. Try Again!');
							}
							else {
								this.showLoader = false;
								this.confirmed = false;
								this.login = false;
								this.register = false;
								this.confirmMail = false;
								this.forget = false;
								this.resetpassword = true;
								this.title = 'Reset passwords';
								this.resetMail = data.text();
							}
						},
						error => {
							console.log(error);
						})
				} else {
					this.error = true;
				};

			});
		}

		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		});

		this.registerForm = this.formBuilder.group({
			emailid: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.pattern('((?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*[@$!%*?&]).{8,30})')]],
		});

		this.forgetForm = this.formBuilder.group({
			emailid: ['', [Validators.required, Validators.email]]
		});

		this.resetPwdForm = this.formBuilder.group({
			password: ['', [Validators.required, Validators.pattern('((?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*[@$!%*?&]).{8,30})')]]
			// password: ['', [Validators.required,Validators.pattern('((?=.*[a-z])(?=.*[A-Z]).{8,30})')]]
		});
	}

	get formControls() { return this.loginForm.controls; }
	get formControlsRF() { return this.registerForm.controls; }
	get formControlsFF() { return this.forgetForm.controls; }
	get formControlsFP() { return this.resetPwdForm.controls; }

	loginFn = () => {
		this.isloginSubmitted = true;
		if (this.loginForm.valid) {
			this.islogging = true;
			this.authService.login(this.loginForm.value).subscribe(
				(data: any) => {
					if (data.status) {
						this.approved = true;
						// localStorage.clear();
						localStorage.setItem('ACCESS_TOKEN', data?.token);
						localStorage.setItem('Customer_ID', data?.customer_id);
						localStorage.setItem('Customer_Name', data?.customer_name);
						localStorage.setItem('Customer_Email', data?.customer_email);
						localStorage.setItem('Customer_Type', data?.customer_type);
						localStorage.setItem('Page_Permissions', JSON.stringify(data?.permission));
						this.islogging = false;
						this.router.navigateByUrl('/home');
					}
					else {
						this.islogging = false;
						this.approved = false;
						this.toastr.warning(data?.message);
					}
				},
				error => {
					this.islogging = false;
					console.error("Error posting data!", error);
					// return Observable.throw(error);
				});
		} else {
			this.toastr.warning("Please fill all fields.")
		}
	}

	registerFn = () => {
		this.isregisterSubmitted = true;
		this.registering = true;
		if (this.registerForm.valid) {
			this.registerForm.value.plan_type = 1;
			if (this.available) {
				this.authService.registration(this.registerForm.value).subscribe(
					(data: any) => {
						if (data?.is_newuser) {
							this.registered = true;
							this.login = true;
							this.register = false;
							this.confirmMail = false;
							this.forget = false;
							this.resetpassword = false;
							this.title = 'Login';
							this.router.navigateByUrl('/login');
						} else {
							this.toastr.warning(`Oops! ${data.message}.`)
						}
						this.registering = false;
					},
					error => {
						this.registering = false;
						console.error("Error posting data!", error);
					});
			} else if (!this.available) {
				this.registering = false;
				this.toastr.warning("Email is already registered. Try again with another email.");
			}
		} else {
			this.registering = false;
			this.toastr.warning("Please fill all fields.");
		}
	}


	confirmResetPwd = (val) => {
		let pwd = (<FormControl>this.resetPwdForm.controls['password']).value;
		if (val == pwd) {
			this.pwdresmatch = true;
		}
		else this.pwdresmatch = false;
	}

	isForgetsending = false;
	forgetFn = () => {
		this.isforgetSubmitted = true;
		this.isForgetsending = true;
		if (this.forgetForm.valid) {
			this.authService.forgetSendMail(this.forgetForm.value).subscribe((data: any) => {
				if (data.status) {
					this.sendmail = true;
					this.isForgetsending = false;
					this.toastr.success(data.message);
				} else {
					this.isForgetsending = false;
					this.toastr.warning(data.message);
				}
			},
				(error) => {
					console.log(error);
				})
		} else {
			this.toastr.warning("Please fill all required fields.")
		}
	}

	checkMailTimeout: any;
	available: boolean = true;
	checkingMail: boolean = false;
	isCheckedEmail: boolean = false;
	isValidEmail = true;
	checkEmailAvailability = (email, isForget?) => {
		var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (email.match(validRegex)) {
			this.checkingMail = true;
			this.isValidEmail = true;
			clearTimeout(this.checkMailTimeout);
			this.checkMailTimeout = setTimeout(() => {
				this.authService.emailAvailability({ emailid: email }).subscribe(
					(data: any) => {
						this.checkingMail = false;
						if (!data.is_available) { this.available = false; this.isCheckedEmail = false; if (!isForget) { this.toastr.warning("Email Not Available. Try Another.") } }
						else { this.available = true; this.isCheckedEmail = true; }
					},
					(error) => {
						this.checkingMail = false;
					})

			}, 2000);
		} else {
			this.isValidEmail = false;
			// this.toastr.warning("Enter a valid email.");
		}
	}

	isResetSubmitted = false;
	isResetSubmitting = false;
	resetPwdFn() {
		if (this.pwdresmatch && !this.resetPwdForm.controls['password'].errors?.pattern) {
			this.isResetSubmitted = true;
			this.isResetSubmitting = true;
			this.resetPwdForm.value.emailid = this.resetMail;
			this.authService.confirmResetPassword(this.resetPwdForm.value).subscribe((data: any) => {
				if (data.text() == 'True') {
					this.router.navigateByUrl('/login');
					this.login = true;
					this.register = false;
					this.confirmMail = false;
					this.forget = false;
					this.resetpassword = false;
					this.title = 'Login';
					this.pwdconfirmed = true;
					this.isResetSubmitting = false;
				}
			},
				(error) => {
					console.log(error);
					this.isResetSubmitting = false;
				})
		} else if (this.resetPwdForm.controls['password'].errors?.pattern) this.toastr.warning("Password not in desired format!");
		else if (!this.pwdresmatch) this.toastr.warning("Password Mismatch!");

	}

	checkRegex(reg, text) {
		let regex;
		if (reg == 'special_character') regex = /.*[!@#$%^&*()+=\[\]{};':"\\|,.<>\/?~`_-].*/;
		else regex = new RegExp(reg, 'g');
		let valid = regex.test(text);
		return valid;
	}

	getRandomInfo() {
		return this.introText[Math.floor(Math.random() * this.introText.length)];
	}

}
