import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpRequest, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from 'environments/environment';
import { AuthService } from 'app/account/auth.service';

import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
const EXCEL_EXTENSION = '.xlsx'; // excel file extension
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class HomeService {

  private get_url = environment.apiUrl;

  private headers = new Headers(
    {
      "Content-Type": 'application/json',
      "Authorization": `token ${this.authService.getToken()}`
    });


  constructor(private _http: Http, private httpClient: HttpClient, private authService: AuthService, private toastr: ToastrService) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // this.toastr.error(errorMessage, 'Error!', {
    //   positionClass: 'toast-top-center',
    // });
    if (error.status == 401) {
      localStorage.clear();
      location.reload();
    }
    return throwError(errorMessage);
  }

  private botListSubject = new BehaviorSubject<any>(null);
  bots$ = this.botListSubject.asObservable();

  updateBotsList(data: any) {
    this.botListSubject.next(data);
  }

  getBotList(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/get_home_data/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }


  getMasterBotList(): Observable<any> {
    return this._http.get(this.get_url + '/get_master_bot_list/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getVendorBotList(cus_id): Observable<any> {
    return this._http.get(this.get_url + '/get_vendor_bot_list/' + cus_id + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }



  getPresignedURL(file_name, botid, type): Observable<any> {
    let data;
    if (type == 'images') {
      data = { data: '/Production/' + botid + '/data/images/' + file_name };
    }
    else if (type == 'videos') {
      data = { data: '/Production/' + botid + '/data/videos/' + file_name };
    }
    else if (type == 'voices') {
      data = { data: '/Production/' + botid + '/data/voices/' + file_name };
    }
    else if (type == 'botdata') {
      data = { data: '/Production/' + botid + '/data/botdata/' + file_name };
    }
    else if (type == 'files') {
      data = { data: '/Production/' + botid + '/data/files/' + file_name };
    }
    else if (type == 'category') {
      data = { data: '/Production/' + botid + '/data/category/' + file_name };
    }
    else if (type == 'subcategory') {
      data = { data: '/Production/' + botid + '/data/subcategory/' + file_name };
    }
    else if (type == 'organisation') {
      data = { data: '/Production/vendor-' + botid + '/data/organisation/' + file_name };
    }
    else if (type == 'broadcast') {
      data = { data: '/Production/' + botid + '/data/broadcast/' + file_name };
    }
    else if (type == 'profile') {
      data = { data: '/Production/customer-' + botid + '/data/profile/' + file_name };
    }
    else if (type == 'template') {
      data = { data: '/Production/' + botid + '/data/template/' + file_name };
    }
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/s3_upload/', body, { headers: this.headers }).pipe(map((res: Response) => res));

  }

  uploadfileAWSS3(fileuploadurl, contenttype, file): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': contenttype, 'x-amz-acl': 'public-read' });
    const req = new HttpRequest('PUT', fileuploadurl, file,
      {
        headers: headers,
        reportProgress: true, //This is required for track upload process
      });
    return this.httpClient.request(req);
  }

  getLogData(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/creyoface_dashboard_dynamic/', body, { headers: this.headers })
      .pipe(map((res: Response) => res), catchError(this.handleError), retry(2));

  }

  facebookInit(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/fb_init/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getUi_Variables(botid): Observable<any> {
    return this._http.get(this.get_url + '/get_ui_variables/' + botid + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  setUi_variables(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/set_ui_variables/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getDeploymentVariables(botid): Observable<any> {
    return this._http.get(this.get_url + '/get_deployment_variables/' + botid + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  addBot(data): Observable<any> {
    let submit = { data: [] };
    submit.data.push(data);
    let body = JSON.stringify(submit);
    return this._http.post(this.get_url + '/create_bot/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  updateBot(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/update_bot/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  addDeploymentVariables(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/set_deployment_variables/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getProfileData(userid): Observable<any> {
    return this._http.get(this.get_url + '/get_profile_variables/' + userid + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getFeedbackData(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_feedback/', body, { headers: this.headers })
      .pipe(map((res: Response) => res), catchError(this.handleError), retry(2));
  }

  resendEmailConfirmation(email): Observable<any> {
    let send = { data: [] };
    send.data.push(email);
    let headers = new Headers({
      'Content-Type': 'application/json'
    });

    let body = JSON.stringify(send);
    return this._http.post(this.get_url + '/send_confirm_email/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  profileEdit(data): Observable<any> {
    let send = { data: [] };
    send.data.push(data);
    let body = JSON.stringify(send);
    return this._http.post(this.get_url + '/set_profile_variables/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getLogChat(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/chat_log/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getTranscriptData(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_users_list/', body, { headers: this.headers })
      .pipe(map((res: Response) => res), catchError(this.handleError), retry(2));
  }

  deleteBot(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/delete_bot/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  sendCodeMail(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/send_codeblock/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getDashboardData(botid): Observable<any> {
    let body = JSON.stringify({ 'botid': botid });
    return this._http.post(this.get_url + '/creyoface_dashboard_static/', body, { headers: this.headers })
      .pipe(map((res: Response) => res), catchError(this.handleError), retry(2));
  }

  passTeleAccessToken(botid, token): Observable<any> {
    let body = JSON.stringify({ 'botid': botid, 'tgbotid': token });
    return this._http.post(this.get_url + '/create_telebot/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  anyBotDeployed(customerid): Observable<any> {
    return this._http.get(this.get_url + '/customer_active_bots/' + customerid + '/', { headers: this.headers })
      .pipe(map((response: Response) =>
        response.json()))


  }

  razorPayService(botid, plan, duration): Observable<any> {
    let body = JSON.stringify({ 'botid': botid, 'plan': plan, 'duration': duration });
    return this._http.post(this.get_url + '/razorpay_integration/', body, { headers: this.headers }).pipe(map((res: Response) => res.json()));
  }

  advancedDashboard(data) {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/analytic_advanced_dashboard/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  // ------------------------------------

  conversationDashboard(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conversation_sankey_diagram/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  conversationActivity(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/analytic_conversation_activity/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  conversationMessages(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/analytic_conversation_messages/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  // --------------------------------------------------

  engagementDashboard(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_intent_track_data/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }


  engagementGoalSegmentation(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_goal_activity/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  engagementGoalFunnel(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_goal_Funnel/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  // ---------------------------------------------------------------------------------------

  userDashboard(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_user_insights/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  userMessage(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_users_by_message/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getLanguageList() {
    return this._http.get('https://api.cognitive.microsofttranslator.com/languages?api-version=3.0&scope=translation')
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  sendMail(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/send_email/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  sendIntegrationMail(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/send_integration_email/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  botIntegrations(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/bot_integrations/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  zohoDetails(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_zoho_details/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  updateZohoDetails(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/update_zoho_details/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getGoogleMapsCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_google_maps_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getGoogleCalendarCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_google_calender_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getZohoCalendarCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_zoho_calendar_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  gettOutlookCalendarCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_outlook_calendar_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getGoogleAnalyticsCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_google_analytics_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getFacebookPixelCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_fb_pixels_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }


  addGoogleMapsCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/add_google_maps_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  addZohoCalendarCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/add_zoho_calendar_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  addOutlookCalendarCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/add_outlook_calendar_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  addGoogleCalendarCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/add_google_calender_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  addGoogleAnalyticsCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/add_google_analytics_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  addFacebookPixelCredentials(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/add_fb_pixels_credentials/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getTemplateBots(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_template_bots/', body, { headers: this.headers }).pipe(map((res: Response) => res.json()));
  }

  getIndustriesBots(): Observable<any> {
    let body = JSON.stringify('');
    return this._http.post(this.get_url + '/get_industry_list/', body, { headers: this.headers }).pipe(map((res: Response) => res.json()));
  }

  getIndustriesTemplates(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_template_bots/', body, { headers: this.headers }).pipe(map((res: Response) => res.json()));
  }

  get_live_user_count(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_live_user_count/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_average_session_length(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_average_session_length/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_avg_conv_per_user(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_avg_conv_per_user/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_total_conversations(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_total_conversations/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_avg_conv_time(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_avg_conv_time/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conv_activity_correlation(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conv_activity_correlation/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conversation_activity(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conversation_activity/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conv_activity_avg_per_user(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conv_activity_avg_per_user/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conversation_sankey_diagram(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conversation_sankey_diagram/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conversation_org_chart(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conversation_org_chart/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conv_messages_corelation(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conv_messages_corelation/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conv_total_messages(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conv_total_messages/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conv_recieved_messages(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conv_recieved_messages/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conv_send_messages(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conv_send_messages/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conv_top_message_out(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conv_top_message_out/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_conv_top_message_in(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_conv_top_message_in/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_intent_track_data(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_intent_track_data/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_goal_Funnel(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_goal_Funnel/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_goal_activity(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_goal_activity/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_user_insights(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_user_insights/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_users_by_message(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_users_by_message/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }


  //---------------------------------analytics new------------------------------------

  get_analytics_data(url, data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + `/${url}/`, body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_goals_list(bot_id): Observable<any> {
    return this._http.get(this.get_url + `/get_goal_list/${bot_id}/`, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_template_distribution(bot_id): Observable<any> {
    return this._http.get(this.get_url + `/get_template_distribution/${bot_id}/`, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  get_promo_template_datas(bot_id): Observable<any> {
    return this._http.get(this.get_url + `/get_promo_template_datas/${bot_id}/`, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }


  // --------------------------------chat log lead------------------------------------

  getTempStoreValues(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/get_temp_store_values/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  addTempStoreValues(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/add_temp_store_values/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError));
  }

  editTempStoreValues(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/edit_temp_store_values/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError));
  }

  addStarredMemVars(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/add_star_rated_storage_variable/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError));
  }


  getMemVars(botid): Observable<any> {
    let body = JSON.stringify({ 'bot_id': botid })
    return this._http.post(this.get_url + '/get_storage_variables/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getStarRatedStorageVars(botid): Observable<any> {
    let body = JSON.stringify({ 'bot_id': botid })
    return this._http.post(this.get_url + '/get_star_rated_storage_variables/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getUserTags(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/get_user_tags/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  addUserTags(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/add_user_tags/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError));
  }

  deploySandbox(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/deploy_sandbox/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError));
  }

  deployWhatsapp(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/deploy_whatsapp/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError));
  }

  //Hierarchy

  getVendorsList(): Observable<any> {
    return this._http.get(this.get_url + '/get_vendor_list/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getOrganizationsList(vendor_id): Observable<any> {
    return this._http.get(this.get_url + '/get_organization_list/' + vendor_id + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getOrganizationBotsList(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/get_organisation_home_data/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getVendorCustomerList(vendor_id): Observable<any> {
    return this._http.get(this.get_url + '/get_vendor_customer_list/' + vendor_id + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  changeOrganization(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/change_organisation_bot/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  transferOwnership(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/change_owner_of_bot/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  addOrganizationDetails(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/add_organisation_details/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError));
  }

  editOrganizationDetails(data): Observable<any> {
    let body = JSON.stringify(data)
    return this._http.post(this.get_url + '/update_organisation_details/', body, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getGeneralDeployVars(botid): Observable<any> {
    return this._http.get(this.get_url + '/get_general_deployment_variables/' + botid + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  setGeneralDeployVars(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/set_general_deployment_variables/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getPrechatDetails(botid): Observable<any> {
    return this._http.get(this.get_url + '/get_prechat_details/' + botid + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  setPrechatDetails(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/set_prechat_details/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getSeoDetails(botid): Observable<any> {
    return this._http.get(this.get_url + '/get_seo_details/' + botid + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  setSeoDetails(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/set_seo_details/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getWasupDetails(botid): Observable<any> {
    return this._http.get(this.get_url + '/get_whatsapp_variables/' + botid + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  whatsappNotificationProd(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/whatsapp_production_notification/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getFacebookDetails(botid): Observable<any> {
    return this._http.get(this.get_url + '/get_facebook_variables/' + botid + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  geTelegramDetails(botid): Observable<any> {
    return this._http.get(this.get_url + '/get_telegram_variables/' + botid + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  getExcelTitles(template_id): Observable<any> {
    return this._http.get(this.get_url + '/excel_mem_vars/' + template_id + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

  public exportToExcel(element: any, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(element);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    var wscols = [];
    for (var i = 0; i < Object.keys(element[0]).length; i++) wscols.push({ wch: 16 });
    ws['!cols'] = wscols;
    XLSX.utils.book_append_sheet(workbook, ws, 'Form');
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);
  }

  deployGBM(data): Observable<any> {
    let body = JSON.stringify(data);
    return this._http.post(this.get_url + '/gbm/', body, { headers: this.headers }).pipe(map((res: Response) => res));
  }

  getGbmData(id): Observable<any> {
    return this._http.get(this.get_url + `/gbm?c_id=${id}`, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
  }

}
