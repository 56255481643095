// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  apiUrl: 'https://dev-canvas-api.creyoface.com/creyoface/v1',
  staticUrl: 'https://creyoface-data-staging.s3.ap-south-1.amazonaws.com/dev/Production/',
  botUrl: 'https://dev-conversation-api.creyoface.com/chat/',
  botStaticUrl: 'https://dev-conversation-api.creyoface.com/files/?file=creyoface_widget.js',
  supportUrl: 'https://dev-support.creyoface.com/#',
  // apiUrl: 'http://localhost:9000/creyoface/v1',
  // staticUrl: 'https://creyofacedata-staging.s3.ap-south-1.amazonaws.com/Production/',
  // botUrl:'http://localhost:8000/chat/',

  production: false,
  fbAppId: '1089787111489801',
  ketto: 167
};

// apiUrl: 'https://dev-canvas-api.creyoface.com/creyoface/v1',
// private get_url = "http://localhost:9000/creyoface/v1";
// private get_url = "https://api.canvas.creyoface.com/creyoface/v1";
// fbAppId: '271623947662991',
// fbAppId: '1089787111489801',
