import { Injectable, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { User } from './user';
import { Http, Response,RequestOptions, Request, RequestMethod, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private get_url = environment.apiUrl;
  private pyData = {data:[]};
  private headers = new Headers(
    {
      "Content-Type": 'application/json'
    });

  constructor(private router: Router,private _http: Http) {}

  public login(userInfo: User){
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this._http.post(this.get_url+'/login/', userInfo, { headers: this.headers }).pipe(map((res: Response) => res.json()));

  }

  public registration(registerInfo){
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    this.pyData.data.push(registerInfo);
    let body = JSON.stringify(this.pyData);
    this.pyData = {data:[]};
    return this._http.post(this.get_url+'/registration/', body, { headers: this.headers }).pipe(map((res: Response) => res.json()));
  }

  public isLoggedIn(){
    return localStorage.getItem('ACCESS_TOKEN') !== null;
  }

  public getToken(){
    return localStorage.getItem('ACCESS_TOKEN');
  }

  public logout(){
    this.clearStorage();
    this.router.navigate(['login']);
  }

  public clearStorage(){
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('Customer_ID');
    localStorage.removeItem('Customer_Name');
    localStorage.removeItem('Customer_Email');
    localStorage.removeItem('Customer_Type');
		localStorage.removeItem('Page_Permissions');
		localStorage.removeItem('botID');
		localStorage.removeItem('botName');
		localStorage.removeItem('botUnique');
    return true;
  }

  public confirmMail(token){
    this.pyData.data.push(token);
    let body = JSON.stringify(this.pyData);
    this.pyData = {data:[]};
    return this._http.post(this.get_url+'/confirm_email/',body, { headers: this.headers }).pipe(map((res:Response) => res.json()))
  }

  public emailAvailability(data){
    return this._http.post(this.get_url+'/check_email_availability/',data, { headers: this.headers }).pipe(map((res:Response) => res.json()))
  }

  public passwordAvailability(password){
    let send = {data:[]};
    send.data.push(password);
    let body = JSON.stringify(send);
    return this._http.post(this.get_url+'/check_password/',body, { headers: this.headers }).pipe(map((res:Response) => res))
  }

  public forgetSendMail(mail){
    return this._http.post(this.get_url+'/forget_password_email/',mail, { headers: this.headers }).pipe(map((res:Response) => res.json()))
  }

  public confirmMailforPassword(token){
    this.pyData.data.push(token);
    let body = JSON.stringify(this.pyData);
    this.pyData = {data:[]};
    return this._http.post(this.get_url+'/confirm_password/',body, { headers: this.headers }).pipe(map((res:Response) => res))
  }

  public confirmResetPassword(pwdMail){
    let send = {data:[]};
    send.data.push(pwdMail);
    let body = JSON.stringify(send);
    return this._http.post(this.get_url+'/save_password/',body, { headers: this.headers }).pipe(map((res:Response) => res))
  }

  getIP(){
    return this._http.get('https://api.ipify.org/?format=json')
    .pipe(map((response: Response) =>
      response.json()))
  }

  getGeoLoc(ip){
    return this._http.get(this.get_url+'/ip-details/'+ip)
    .pipe(map((response: Response) =>
      response.json()))
  }

}

