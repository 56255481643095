import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { NavbarComponent } from '../shared/navbar/navbar.component';
// import { JoyrideService } from 'ngx-joyride';
import { Location } from '@angular/common';
import { HomeService } from 'app/shared/general/services/home.service';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';


export interface RouteInfo {
    path: string;
    title: string;
    class: string;
    info: string;
    parent: string;
    icon?: string;
    icon_img?: string;
}

export const ROUTES: RouteInfo[] = [
    {
        "path": "/home",
        "title": "Home",
        "icon": "fa fa-home",
        "class": "",
        "info": "Navigate to 'Home'. Create/view assistants here",
        "parent": "home"
    },
    {
        "path": "/analytics",
        "title": "Analytics",
        "icon": "fa fa-pie-chart",
        "class": "",
        "info": "Know about assistant performance, advanced analytical reports and conversational transcripts",
        "parent": "analytics"
    },
    {
        "path": "/canvas/design",
        "title": "Canvas",
        "icon": "nc-icon nc-vector",
        "class": "",
        "info": "Navigate to 'Canvas'. Design the assistant workflow using drag-and-drop widgets",
        "parent": "canvas"
    },
    {
        "path": "/canvas/knowledge-base",
        "title": "Knowledge Base",
        "icon": "fa fa-list",
        "class": "",
        "info": "Navigate to 'Knowledge Base'. Manage Knowledge base for better performance. View/Train unanswered questions by assistant.",
        "parent": "canvas"
    },
    {
        "path": "/canvas/database",
        "title": "Database",
        "icon": "fa fa-database",
        "class": "",
        "info": "Database",
        "parent": "canvas"
    },
    {
        "path": "/canvas/learn",
        "title": "Learn",
        "icon": "fa fa-server",
        "class": "",
        "info": "Navigate to 'Creyo-NLU'. Manage level of intelligence of the assistant. Use proprietary context understanding mechanism to enhance assistant performance.",
        "parent": "canvas"
    },
    {
        "path": "/campaign/templates",
        "title": "Templates",
        "icon": "fa fa-cogs",
        "class": "",
        // "info": "Navigate to 'Settings'. You can find all the configurational settings of an assistant here. Be it skin or integration or deployment.",
        "info": "Templates",
        "parent": "campaign"
    },
    {
        "path": "/campaign/automation",
        "title": "Automation",
        "icon": "fa fa-clock-o",
        "class": "",
        "info": "",
        "parent": "campaign"
    },
    {
        "path": "/campaign/broadcast",
        "title": "Broadcast",
        "icon": "fa fa-bullhorn",
        "class": "",
        "info": "Broadcast messages to your customers based on business logic. Engage them with personalized interactions.",
        "parent": "campaign"
    },
    {
        "path": "/channels/website",
        "title": "Website",
        "icon": "fa fa-window-maximize",
        "class": "",
        "info": "Website",
        "parent": "channels"
    },
    {
        "path": "/channels/whatsapp",
        "title": "Whatsapp",
        "icon": "fa fa-whatsapp",
        "class": "",
        "info": "Website",
        "parent": "channels"
    },
    {
        "path": "/channels/facebook",
        "title": "Facebook",
        "icon": "fa fa-facebook",
        "class": "",
        "info": "Facebook",
        "parent": "channels"
    },
    {
        "path": "/channels/telegram",
        "title": "Telegram",
        "icon": "fa fa-telegram",
        "class": "",
        "info": "Telegram",
        "parent": "channels"
    },
    {
        "path": "/channels/gbm",
        "title": "GBM",
        "icon": "fa fa-google",
        "class": "",
        "info": "GBM",
        "parent": "channels"
    },
    {
        "path": "/channels/request/wechat",
        "title": "WeChat",
        "icon": "fa fa-wechat",
        "class": "",
        "info": "WeChat",
        "parent": "channels"
    },
    {
        "path": "/channels/request/kik",
        "title": "Kik",
        "icon_img": "/assets/images/icons/channels/kik.svg",
        "class": "",
        "info": "Kik",
        "parent": "channels"
    },
    {
        "path": "/channels/request/slack",
        "title": "Slack",
        "icon": "fa fa-slack",
        "class": "",
        "info": "Slack",
        "parent": "channels"
    },
    {
        "path": "/channels/request/twitter",
        "title": "Twitter",
        "icon": "fa fa-twitter",
        "class": "",
        "info": "Twitter",
        "parent": "channels"
    },
    {
        "path": "/channels/request/line",
        "title": "Line",
        "icon_img": "/assets/images/icons/channels/line.svg",
        "class": "",
        "info": "Line",
        "parent": "channels"
    },
    {
        "path": "/channels/request/viber",
        "title": "Viber",
        "icon_img": "/assets/images/icons/channels/viber.svg",
        "class": "",
        "info": "Viber",
        "parent": "channels"
    },
    {
        "path": "/teams/list",
        "title": "Teams",
        "icon_img": "/assets/images/icons/teams/team.svg",
        "class": "",
        "info": "Teams",
        "parent": "teams"
    },
    {
        "path": "/teams/organizations",
        "title": "Organizations",
        "icon_img": "/assets/images/icons/teams/organization.svg",
        "class": "",
        "info": "Organizations",
        "parent": "teams"
    }
];


// { path: '/insights',     title: 'Insights',         icon:'fa fa-dashboard',     class: '' , info: "Navigate to 'Insights'. Know about assistant performance, advanced analytical reports and conversational transcripts" , step:'step1'},
// { path: '/profile',       title: 'Profile',           icon:'nc-single-02',        class: '' },
// { path: '/upgrade',       title: 'Upgrade to PRO',    icon:'nc-spaceship',        class: 'active-pro',  info: ''},
// { path: '/accounts',       title: 'Accounts',    icon:'ti-wallet',        class: '',  info: '', step:'step8'},
// ];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateY(-100%)' }),
                animate('300ms ease-in', style({ transform: 'translateY(0%)' }))
            ]),
            transition(':leave', [
                animate('300ms ease-in', style({ transform: 'translateY(-50%)' }))
            ])
        ])
    ]
})

export class SidebarComponent implements OnInit {
    menuItems: any[];
    toggle: boolean;
    isHover: boolean = false;
    anyBot: boolean = true;
    isMin: boolean = false;
    location: Location;
    botID: number = parseInt(localStorage.getItem('botID'), 10);
    customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
    customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
    pagePermissions: any;
    urlArray = [];

    @ViewChild(NavbarComponent) navBar: NavbarComponent;

    constructor(
        private homeService: HomeService, location: Location, private toastr: ToastrService, private router: Router) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) this.sidebarInit();
        });
        this.location = location;
    }

    ngOnInit() {
        this.sidebarInit();
        var title = this.location.prepareExternalUrl(this.location.path());
        if (title.charAt(0) === '#') {
            title = title.slice(1);
        }
        if (title == '/intent' || title == '/entity') {
            this.toggle = true;
        }
        this.anyBotDeployed();


    }

    sidebarInit() {
        const currentUrl = this.router.url;
        const parts = currentUrl.split('/');
        this.urlArray = parts.filter(Boolean);
        this.pagePermissions = JSON.parse(localStorage.getItem('Page_Permissions'));
        this.routesUpdate();
    }

    routesUpdate() {
        var ROUTES_UPDATED = ROUTES;
        if (!this.pagePermissions.show_settings_page || !this.pagePermissions.analytics_view || !this.pagePermissions.show_broadcast_page) {
            if (!this.pagePermissions.show_settings_page) {
                ROUTES_UPDATED = ROUTES_UPDATED.filter(ae => ae.title != 'Settings');
            }
            if (!this.pagePermissions.analytics_view) {
                ROUTES_UPDATED = ROUTES_UPDATED.filter(ae => ae.title != 'Analytics');
            }
            if (!this.pagePermissions.show_broadcast_page) {
                ROUTES_UPDATED = ROUTES_UPDATED.filter(ae => ae.title != 'Broadcast');
            }
            this.menuItems = ROUTES_UPDATED.filter(menuItem => menuItem);
        } else {
            ROUTES_UPDATED = ROUTES;
            this.menuItems = ROUTES_UPDATED.filter(menuItem => menuItem);
        }
        this.menuItems = this.menuItems.filter(menu => this.urlArray.includes(menu.parent));

    }

    anyBotDeployed() {
        this.homeService.anyBotDeployed(this.customerID).subscribe((data) => {
            if (data == false) {
                this.anyBot = false;
                if (this.customerType == 0 || this.customerType == 1) {
                    this.anyBot = true;
                }
            }
        },
            (error) => {
                console.log(error);
            })
    }

    toggleSub(title) {
        if (title == 'Creyo-NLU') {
            this.toggle = !this.toggle;
        }
        else this.toggle = false;
    }

    sidebarMinimizeonHover() {
        const sidebarbar = document.getElementsByClassName('sidebar')[0];
        const panel = document.getElementsByClassName('main-panel')[0];
        this.isMin = this.navBar.isMinimized;
        if (this.isMin) {
            if (this.isHover) {
                sidebarbar.classList.remove('minibar');
                panel.classList.remove('minipanel');
            }
            else {
                sidebarbar.classList.add('minibar');
                panel.classList.add('minipanel');
            }
        }
    }

    showAlert(title) {
        if (title != 'Home') {
            this.toastr.warning('You must have atleast one bot to access this page!', 'Please Create a Bot!', {
                positionClass: 'toast-top-center',
            });
        }
    }

}
