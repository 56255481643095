import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { LoaderModule } from './shared/loader/loader.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { GeneralPagesModule } from './shared/general/general-pages/general-pages.module';

import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { ModalModule } from 'ngx-bootstrap/modal';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccountComponent } from './account/account.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './account/auth.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AccountComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      scrollPositionRestoration: 'enabled'
    }),
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    NavbarModule,
    LoaderModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      positionClass: 'toast-top-right'
    }),
    FooterModule,
    HttpClientModule,
    GeneralPagesModule,
    HttpModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgxDaterangepickerMd.forRoot()
    // AdminLayoutModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
