import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HomeService } from '../general/services/home.service';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
    imports: [RouterModule, CommonModule, NgbModule, FormsModule, ModalModule.forRoot()],
    declarations: [NavbarComponent],
    exports: [NavbarComponent],
    providers: [HomeService],
})

export class NavbarModule { }
