import { Injectable, OnInit } from '@angular/core';
import { Http, Response, RequestOptions, Request, RequestMethod, Headers } from '@angular/http';
import { HttpRequest, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';

import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthService } from 'app/account/auth.service';

import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  private get_url = environment.apiUrl;
  private headers = new Headers(
    {
      "Content-Type": 'application/json',
      "Authorization": `token ${this.authService.getToken()}`
    });

  constructor(private _http: Http, private httpClient: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  getWalletDetails(cus_id) {
    return this._http.get(this.get_url + '/get_wallet_details/' + cus_id + '/', { headers: this.headers })
      .pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(5));
  }

  getTokenFromMail(mail) {
    return this._http.post(`${this.get_url}/get_sso_token/`, { sso_email: mail }, { headers: this.headers })
      .pipe(map((res: Response) => res.json()), retry(2));
  }
}
