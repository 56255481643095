<div class="sidebar-wrapper">
  <div class="logo">
    <a href="" class="simple-text logo-mini">
      <div class="logo-image-small">
        <img src="assets/img/creyoface.gif">
      </div>
    </a>
    <a [routerLink]="['/home']" class="simple-text logo-normal">
      Creyoface
      <!-- <sub>BETA</sub> -->
    </a>
  </div>
  <ul class="nav" *ngIf="anyBot">
    <li *ngFor="let menuItem of menuItems; let i =index;" routerLinkActive="active" class="{{menuItem.class}}"
      title="{{menuItem.info}}">
      <a [routerLink]="[menuItem.path]" (click)="toggleSub(menuItem.title)" tooltip="{{menuItem.info}}">
        <i *ngIf="menuItem.icon" [class]="menuItem.icon"></i>
        <img *ngIf="menuItem.icon_img" [src]="menuItem.icon_img" width="20" alt="icon">
        <p>{{menuItem.title}}</p>
      </a>
    </li>
  </ul>
  <ul class="nav" *ngIf="!anyBot">
    <li *ngFor="let menuItem of menuItems; let i =index;" [ngClass]="{'active':menuItem.title=='Home'}"
      class="{{menuItem.class}}" title="{{menuItem.info}}">
      <a [routerLink]="['/home']" (click)="showAlert(menuItem.title)" [ngClass]="{'active':menuItem.title=='Home'}"
        (click)="toggleSub(menuItem.title)" tooltip="{{menuItem.info}}">
        <i *ngIf="menuItem.icon" [class]="menuItem.icon"></i>
        <img *ngIf="menuItem.icon_img" [src]="menuItem.icon_img" width="15">
        <p>{{menuItem.title}}</p>
      </a>
    </li>
  </ul>
</div>